import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import './ButtonComponent.css';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';
import { backendurl, dashboardurl } from '../../environment';

function ButtonComponent({
  onLoginClick,
  auth: { isAuthenticated, loading, user },
  logout,
}) {
  const history = useHistory();

  if (loading) {
    return (
      <div style={{ marginLeft: '40%', marginTop: '10%' }} className="loader">
        <Oval
          ariaLabel="loading-indicator"
          height={50}
          width={50}
          strokeWidth={5}
          color="#42b76d"
          secondaryColor="#42b7a7"
        />
      </div>
    );
  }
  if (isAuthenticated && !loading && user && localStorage.getItem('token')) {
    return (
      <>
        <Link to={`/profile/${user.slug}`}>
          <button className="btn btn-block custom-btn user-details">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                outline: 'none',
                objectFit: 'contain',
              }}>
              <ListItemButton className="listItem">
                <ListItemAvatar className="listAvatar">
                  <Avatar
                    className="avatar"
                    height="45"
                    width="45"
                    alt={`Avatar`}
                    src={user.avatar}
                  />
                </ListItemAvatar>
                <ListItemText className="listText" primary={user.name} />
              </ListItemButton>
              <i
                className="fa fa-sign-out nav_icon"
                aria-hidden="true"
                style={{
                  marginRight: '25px',
                  color: '#42b7a7',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  logout();
                  history.push('/');
                  window.location.reload();
                }}></i>
            </div>
          </button>
        </Link>
      </>
    );
  }
  return (
    <>
      <button
        className="btn btn-success btn-block custom-btn"
        onClick={() => onLoginClick()}
        style={{
          width: '100%',
          marginTop: '2em',
          backgroundColor: '#42b7a7',
          border: 'none',
          height: '3em',
          borderRadius: '1em',
          maxWidth: 310,
          marginLeft: '0.5em',
        }}>
        Join
      </button>
    </>
  );
}

ButtonComponent.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(ButtonComponent);
