import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './profile.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Helmet } from 'react-helmet';

// ? React-Redux

import { useDispatch, useSelector } from 'react-redux';
import { specificUserDetailAction } from '../../redux-action-reducer/specific-user';
import PostCard from '../../components/PostCard';
import { setAlert } from '../../actions/alert';

const MemberProfile = ({ match }) => {
  const dispatch = useDispatch();
  const [showAppt, setshowAppt] = useState(true);
  const [showInput, setshowInput] = useState(false);
  const [phone, setPhone] = useState('');

  const { loading, member } = useSelector((state) => state.memberProfile);
  const [avatar, setAvatar] = useState('');

  const onClickBook = (member) => {
    setshowAppt(false);
    setshowInput(true);
    console.log(member.user[0].phone);
  };

  const onClickContinue = async () => {
    setshowAppt(false);
    setshowInput(false);

    const headers = {
      'Content-Type': 'application/json',
    };

    let body = {
      name: phone,
      phone: member.user[0].phone,
      phone_no: phone,
      service: 'Career Counselling',
      from: 'Community',
    };
    const res = await axios.put(
      `https://api.careernaksha.com/api/community/users/leads`,
      body,
      { headers }
    );

    dispatch(setAlert('Thanks We Will Get Back to You', 'success'));
  };

  const getAvatar = async (member) => {
    delete axios.defaults.headers.common['x-auth-token'];

    let user = await axios.get(
      `https://dashboard.careernaksha.com/career-gurus/?Phone=${member.user[0].phone}`
    );

    if (member.data.length) {
      setAvatar(member.data[0]?.Image);
    } else {
      setAvatar(
        'https://ik.imagekit.io/careerNaksha/a1_300x-8_iMJU-CGJXB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653136430547'
      );
    }
  };

  useEffect(() => {
    dispatch(specificUserDetailAction(match.params.slug));
  }, [match, dispatch]);

  if (!loading && member) {
    getAvatar(member);
    const { user, posts } = member;

    return (
      <div className="profile">
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="img">
          <img
            src={avatar !== '' ? avatar : user[0].avatar}
            alt={user[0].name}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <h5>{user[0].name}</h5>
          {user[0].isVerified && <VerifiedIcon color="primary" />}
        </div>

        <div className="row" style={{ width: '100%', marginTop: '15px' }}>
          <div className="col col-md-6 col-12">
            <h6>Description</h6>
            {user[0].about && <p>{user[0].about}</p>}
          </div>
          {user[0].specialisation && (
            <div className="col col-md-6 col-12">
              <h6>Specialization</h6>
              <ul>
                {user[0].specialisation.academicCounsellor && (
                  <li>Academic Counselling</li>
                )}
                {user[0].specialisation.behaviouralCounsellor && (
                  <li>Behavioural Counselling</li>
                )}
                {user[0].specialisation.industrialCounsellor && (
                  <li>Industrial Counselling</li>
                )}
                {user[0].specialisation.informationCounsellor && (
                  <li>Information Counselling</li>
                )}
                {user[0].specialisation.isCareerCounsellor && (
                  <li>Career Counselling</li>
                )}
                {user[0].specialisation.isClinicalCounsellor && (
                  <li>Clinical Counselling</li>
                )}
                {user[0].specialisation.isHrCounsellor && (
                  <li>HR Counselling</li>
                )}
                {user[0].specialisation.mentalCounsellor && (
                  <li>Mental Health Counselling</li>
                )}
                {user[0].specialisation.therapyCounsellor && (
                  <li>Therapy Based Counselling</li>
                )}
              </ul>
            </div>
          )}
        </div>

        {showAppt && (
          <Button
            variant="contained"
            disableElevation
            style={{
              backgroundColor: '#42b2a7',
              marginTop: '10px',
              borderRadius: '10px',
            }}
            onClick={() => onClickBook(member)}>
            Book Appointment
          </Button>
        )}

        {showInput && (
          <>
            <TextField
              required
              id="outlined-required"
              label="Enter Mobile Number"
              style={{
                marginTop: '20px',
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <Button
              variant="contained"
              disableElevation
              style={{
                backgroundColor: '#42b2a7',
                marginTop: '15px',
                borderRadius: '10px',
                width: '150px',
                marginBottom: '12px',
              }}
              onClick={() => onClickContinue()}>
              Continue
            </Button>
          </>
        )}

        <div style={{ marginTop: '15px', width: '100%' }}>
          {posts.map((post) => (
            <PostCard post={post} />
          ))}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default MemberProfile;
