import React,{useState,useEffect} from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import axios from 'axios';
import './admin.css';
import Button from '@mui/material/Button';
import Pagination from '../../components/layout/Pagination';

function AdminListOrders({auth,history}) {

    const [orders, setorders] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(20);

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = orders.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const checkVerified = () => {
        const token = localStorage.getItem('token');
        if(auth.user?.isAdmin === false || token == null){
          history.push("/");
        }
      }

      const fetchOrders = async () => {
        let orders = await axios.get('https://api.careernaksha.com/api/communityPsychometrics/list-orders');
        console.log(orders.data);
        setorders(orders.data);
      }

      useEffect(() => {
       checkVerified();
       fetchOrders();
      }, [])
      
  return (
    <div >
      <h3 style={{marginTop:'30px',textAlign:'center'}}>List Of Successfull Order Details</h3>
    <table style={{marginTop:'30px'}}>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>Database Id</th>
          <th>Order Id</th>
          <th>Payment Id</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {currentPosts.map((u,i) => (
          <tr>
            <td>{i+1}</td>
            <td>{u._id}</td>
            <td>{u.razorpay.orderId}</td>
            <td>{u.razorpay.paymentId}</td>
            <td>{u.createdDate.slice(0,10)}</td>

            {/* <td>{u.avatar}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination
            postsPerPage={postPerPage}
            totalPosts={orders.length}
            currentPage
            paginate={paginate}
          />
  </div>
  )
}

AdminListOrders.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});


export default connect(mapStateToProps)(AdminListOrders);