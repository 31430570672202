import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { login } from "../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./Login.css";
import { Link } from "react-router-dom";

function Login({ onCloseClick, onRegisterClick, login }) {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const { email, password } = formData;

	const onChangetext = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });
	const loginHandler = async () => {
		login(email.trim(), password);
		onCloseClick();
	};

	return (
		<Card
			sx={{ minWidth: 200, marginTop: 4, maxWidth: 310, marginLeft: 1 }}
			style={
				{
					// boxShadow: "0px 0px 4px rgb(75, 73, 73)",
				}
			}
		>
			<h1 className="login-heading">Login</h1>
			<CardContent>
				<TextField
					id="outlined-basic"
					label="Enter Email"
					variant="outlined"
					name="email"
					value={email}
					onChange={(e) => onChangetext(e)}
					style={{ marginBottom: 10, width: "100%" }}
				/>
				<TextField
					id="outlined-basic"
					label="Enter Password"
					name="password"
					variant="outlined"
					style={{ marginTop: 3, width: "100%" }}
					value={password}
					type="password"
					onChange={(e) => onChangetext(e)}
				/>
			</CardContent>

			<CardActions
				style={{
					display: "flex",
					justifyContent: "space-evenly",
				}}
			>
				<Button size="small" onClick={() => loginHandler()} style={{ color: "#42b7a7" }}>
					Login
				</Button>
				<Button
					size="small"
					onClick={() => onRegisterClick()}
					style={{ color: "#42b7a7" }}
				>
					Register
				</Button>
				<Link
					to="/forget-password/"
					onClick={() => {
						onCloseClick();
					}}
				>
					<Button size="small" style={{ color: "#42b7a7" }}>
						Forgot
					</Button>
				</Link>
				<Button size="small" onClick={() => onCloseClick()} style={{ color: "#42b7a7" }}>
					Close
				</Button>
			</CardActions>
		</Card>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

Login.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, { login })(Login);
