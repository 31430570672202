import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { connect,useDispatch } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import { setAlert } from '../../actions/alert';
import { loaduser } from '../../actions/auth';

function EditProfile({ auth,history }) {
    const [email, setemail] = useState('');
    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [brand, setbrand] = useState('');
    const [id, setid] = useState('');
    const dispatch = useDispatch();

    const submitDetails = async() => {
        const config = {
			headers: {
				"Content-Type": "application/json",
			},
        };
        let data = {
            email:email,
            name:name,
            number:phone,
            id:id,
            brand:brand
        }

        let data1 = { 
            phone:auth.user?.phone,
        }

        console.log(data);

        let updata = await axios.post('https://api.careernaksha.com/api/community/users/updateProfile',data,config);

        if(updata.data){
            dispatch(setAlert('Your Profile Is Updated Successfully','success'));
            dispatch(loaduser());
        }
    }

    const fillEmail = () => {
        if(auth.user?.email){
          setemail(auth.user.email);
        }
      }

      const fillBrand = () => {
        if(auth.user?.brandName){
          setbrand(auth.user.brandName);
        }
      }
    const fillName = () => {
        if(auth.user?.name){
          setname(auth.user.name);
        }
      }

      const fillId = () => {
        if(auth.user?._id){
          setid(auth.user._id);
        }
      }
    const fillPhone = () => {
        if(auth.user?.phone){
          setphone(auth.user.phone);
        }
      }

      const checkVerified = () => {
        const token = localStorage.getItem('token');
        if(token == null){
          history.push("/");
        }
      }

      useEffect(() => {
        fillEmail();
        fillName();
        fillPhone();
        fillBrand();
        fillId();
        checkVerified();
      }, [auth])

  return (
    <div>
    <h1 style={{textAlign:'center',marginTop:'20px'}}>Let's Update Your Profile</h1>
    <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <TextField  
        InputLabelProps={{
  style: { color: '#000' }, 
}} style={{width:'60%',background:'#fff'}} id="outlined-basic" label="Email Id"  value={email} onChange={(e) => setemail(e.target.value)}/>   
    </div>

    <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <TextField  
        InputLabelProps={{
  style: { color: '#000' }, 
}} style={{width:'60%',background:'#fff'}} id="outlined-basic" label="Name"  value={name} onChange={(e) => setname(e.target.value)}/>   
    </div>
    <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <TextField  
        InputLabelProps={{
  style: { color: '#000' }, 
}} style={{width:'60%',background:'#fff'}} id="outlined-basic" label="Phone"  value={phone} onChange={(e) => setphone(e.target.value)}/>   
    </div>
    <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
        <TextField  
        InputLabelProps={{
  style: { color: '#000' }, 
}} style={{width:'60%',background:'#fff'}} id="outlined-basic" label="Company Name"  value={brand} onChange={(e) => setbrand(e.target.value)}/>   
    </div>
    <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}> 
    </div>
       <div style={{display:'flex',justifyContent:'center'}}>
    <Button variant="text" style={{marginTop:'20px',color:'#000'}} onClick={() => submitDetails()}>EDIT PROFILE</Button>
    </div>
</div>
  )
}

EditProfile.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(EditProfile);