import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { backendurl } from '../../environment';

const AssignTestCode = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [error, setError] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    const query = new URLSearchParams(url.search);
    setCode(query.get('code'));
  }, [window.location.href]);

  const onAssign = async () => {
    if (!code || !email || !phone) {
      return setError('All fields are required');
    }
    setError('');
    const data = {
      code,
      email: email.toLowerCase(),
      phone: '+' + phone,
      counsellorid: localStorage.getItem('userId'),
    };
    await axios
      .post(`${backendurl}/com/assignTestCodeToStudent`, data)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          alert(res.data.message);
          window.location.href = '/mycodes';
        }
      })
      .catch((error) => {
        setError(error.response?.data?.message);
      });
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>
        Assign Test Code to Student
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '40px',
        }}>
        <TextField
          InputLabelProps={{
            style: { color: '#000' },
          }}
          style={{ width: '60%', background: '#fff' }}
          id="outlined-basic"
          label="Test Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <TextField
          InputLabelProps={{
            style: { color: '#000' },
          }}
          style={{ width: '60%', background: '#fff' }}
          id="outlined-basic"
          label="Student's Email Id"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <PhoneInput
          style={{ width: '60%', height: '3rem' }}
          inputStyle={{ height: '100%', width: '100%' }}
          country={'in'}
          value={phone}
          onChange={(p) => {
            setPhone(p);
          }}
          countryCodeEditable={false}
          placeholder=""
        />

        <Button
          variant="text"
          style={{
            marginTop: '20px',
            color: '#fff',
            backgroundColor: 'rgb(66, 183, 167)',
            borderRadius: '5px',
            padding: '10px 20px',
          }}
          onClick={() => {
            onAssign();
          }}>
          Assign
        </Button>

        <div
          style={{
            color: '#f00',
            fontSize: 'small',
          }}>
          {error}
        </div>
      </div>
    </div>
  );
};

export default AssignTestCode;
