import React,{useEffect,useState} from 'react'
import axios from 'axios';
import {connect,useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import Pagination from '../../components/layout/Pagination';

function AdminVerifiedUSers({auth,history}) {
    const [users, setusers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage] = useState(20);

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const checkVerified = () => {
        const token = localStorage.getItem('token');
        if(auth.user?.isAdmin === false || token == null){
          history.push("/");
        }
      }

      const getVerifiedUsers = async () => {
        let user_ver_data = await axios.get('https://api.careernaksha.com/api/community/users/getVerified');
        setusers(user_ver_data.data);
        
    }

    useEffect(() => {
        checkVerified();
        getVerifiedUsers();
     
    }, [])
    

  return (
    <div>
<h3 style={{textAlign:'center'}}>Verified Users</h3>
<table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((u) => (
            <tr>
              <td>{u.name}</td>
              <td>{u.email}</td>
              <td>{u.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
            postsPerPage={postPerPage}
            totalPosts={users.length}
            currentPage
            paginate={paginate}
          />
    </div>
  )
}


AdminVerifiedUSers.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});



export default connect(mapStateToProps)(AdminVerifiedUSers);
