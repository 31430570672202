import React,{useEffect,useState} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './registerRefer.css';
import { loaduser, registerRefer } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';


function RegisterNewRefer({registerRefer,loaduser,auth,history}) {
  const [counsellorSlug, setcounsellorSlug] = useState('');
  useEffect(() => {
   setcounsellorSlug(history.location.pathname.substr(7,history.location.pathname.length));
  }, [])
  

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });

  const sendMail = async() => {
    let data = {
      name:name,
      email:email,
    }

    let data_mail = await axios.post('https://api.careernaksha.com/api/communityRegisterMail',data);
  }

  const { name, email, password, phone } = formData;
  const onChangetext = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const registerHandler = async () => {
    console.log(counsellorSlug);
    registerRefer({ name, email, password, phone},counsellorSlug);
    sendMail();
    setTimeout(() => {
      history.push("/");
    }, 1500);
  };

  return (
    <div className='outerDiv'>
        <div style={{
            marginTop:'30px',
            display:'block'
        }}>
        <h3 style={{textAlign:'center',paddingTop:'20px'}}>Join Our Counsellors Community</h3>
        <h6 style={{textAlign:'center'}}>Please Fill Below Details And Get Access To Our Community</h6>
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
            <TextField
             value={name}
             name='name'
             onChange={(e) => onChangetext(e)}  
            InputLabelProps={{
      style: { color: '#000' }, 
   }} style={{width:'55%',background:'#fff'}} id="outlined-basic" label="Name"  />   
      
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'15px'}}>
            <TextField  
             name='email'
             value={email}
             onChange={(e) => onChangetext(e)}
            InputLabelProps={{
      style: { color: '#000' }, 
   }} style={{width:'55%',background:'#fff'}} id="outlined-basic" label="Email Id"  />   
      
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'15px'}}>
            <TextField  
            name='phone'
             value={phone}
             onChange={(e) => onChangetext(e)}
            InputLabelProps={{
      style: { color: '#000' }, 
   }} style={{width:'55%',background:'#fff'}} id="outlined-basic" label="Phone"  />   
      
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'15px'}}>
            <TextField  
            name='password'
             value={password}
             onChange={(e) => onChangetext(e)}
            InputLabelProps={{
      style: { color: '#000' }, 
   }} style={{width:'55%',background:'#fff'}} id="outlined-basic" label="Password"  />   
      
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>

<Button onClick={() => {registerHandler()}} variant="text" style={{marginTop:'20px',color:'#000',marginBottom:'20px'}}>REGISTER</Button>


</div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: PropTypes.object.isRequired,
});

RegisterNewRefer.propTypes = {
  setAlert: PropTypes.func.isRequired,
  registerRefer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setAlert, registerRefer })(
  RegisterNewRefer
);
