import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './admin.css';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { setAlert } from '../../actions/alert';
import Pagination from '../../components/layout/Pagination';

const AdminRequestsManage = ({ auth, history }) => {
  const [user, userData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = user.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const dispatch = useDispatch();
  const checkVerified = () => {
    const token = localStorage.getItem('token');
    if (auth.user?.isAdmin === false || token == null) {
      history.push('/');
    }
  };

  const delRequest = async (id) => {
    try {
      let data_api = {
        id: id,
      };
      let res = await axios.post(
        'https://api.careernaksha.com/api/communityPsychometrics/deleteRequest',
        data_api
      );

      console.log(res);

      axios
        .get(
          'https://api.careernaksha.com/api/community/users/getproductrequests'
        )
        .then((response) => {
          userData(response.data);
        });
    } catch (error) {}
  };

  const verifyAccount = async (email) => {
    let data_api = {
      email: email,
    };

    let data = await axios.post(
      'https://api.careernaksha.com/api/community/users/updateVerificationStatus',
      data_api
    );

    if (data.data) {
      dispatch(setAlert('Verification Status Changed', 'success'));
    }
  };

  useEffect(() => {
    checkVerified();
    axios
      .get(
        'https://api.careernaksha.com/api/community/users/getproductrequests'
      )
      .then((response) => {
        userData(response.data);
      });
  }, []);

  const changeAvatar = async (userId, newAvatar) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      newAvatar: newAvatar,
      // "https://yt3.ggpht.com/ytc/AKedOLQf5MBcFSDzo2FeZIXSqafCvdRMGjW2C-0j8RpD=s900-c-k-c0x00ffffff-no-rj",
      // "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png",
    };

    axios
      .get('http://localhost:3000/api/community/users/getproductrequests')
      .then((response) => {
        userData(response.data);
      });
  };

  if (!user) return null;

  return (
    <div className="users-manager">
      <Link to="/admin/" className="navigation-btn">
        <p>Manage Post</p>
      </Link>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Action 1</th>
            <th>Action 2</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((u) => (
            <tr>
              <td>{u.name}</td>
              <td>{u.email}</td>
              <td>{u.phone}</td>

              <td>
                <Button
                  style={{ color: '#000' }}
                  onClick={() => verifyAccount(u.email)}>
                  VERIFY ACCOUNT
                </Button>
              </td>
              <td
                onClick={() => {
                  delRequest(u._id);
                }}>
                <i className="fa-solid fa-ban"></i>
              </td>
              {/* <td>{u.avatar}</td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        postsPerPage={postPerPage}
        totalPosts={user.length}
        currentPage
        paginate={paginate}
      />
    </div>
  );
};

AdminRequestsManage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminRequestsManage);
