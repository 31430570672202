import axios from "axios";
import React, { useEffect, useState } from "react";

function Users() {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      const mem = await axios.get(
        "https://api.careernaksha.com/api/community/users/getUsers"
      );
      console.log(typeof mem.data);
      setMembers(mem.data);
      console.log(typeof members);
    };

    fetchResults();
  }, []);

  return (
    <div>
      <h1>Users</h1>
    </div>
  );
};

export default Users;
