import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import './DetailPost.css';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { Button } from '@mui/material';
import HtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';

import {
  getPost,
  addComment,
  addLike,
  deleteComment,
} from '../../actions/post';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Moment from 'react-moment';
import { setAlert } from '../../actions/alert';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}></Box>
);
function DetailPost({
  getPost,
  addComment,
  post: { post, loading },
  match,
  history,
  auth: { user },
}) {
  const [text, setText] = useState('');
  const [postId, setPostId] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setPostId(match.params.id);
    getPost(match.params.id);
  }, [getPost, match]);

  const onCommentSubmit = () => {
    if (!localStorage.getItem('token')) {
      alert('Please Login To Post A Comment');
      return;
    }
    addComment(post._id, { text });
    setText('');
  };
  const format = (d) => {
    return d.substring(0, 15);
  };

  const routeToHome = () => {
    history.push('/');
  };
  const routeToCreate = () => {
    history.push('/create-post');
  };

  // if (!localStorage.getItem('token')) {
  //   return <h1 style={{ textAlign: 'center' }}>Please Login</h1>;
  // }
  return (
    <>
      <div>
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          {post && <title>CareerNaksha Community | {post.title}</title>}
        </Helmet>
        <div className="card mb-3 custom-card">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">
                  Welcome to Counsellors Community by CCI & CareerNaksha
                </h5>
                <p
                  className="card-text"
                  style={{ fontSize: '18px', paddingTop: '30px' }}>
                  This community is for you to practice counselling better.
                  Communicate with other fellow counsellors. Ask your questions,
                  provide answers and share your thoughts with the community. We
                  reward the top counsellors for their unbiased knowledge &
                  ideas.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img
                src="https://ik.imagekit.io/careerNaksha/Frame_1__2__27UoD81De.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649230937446"
                className="card-img custom-img"
                style={{
                  paddingTop: '40px',
                  imageRendering: '-webkit-optimize-contrast',
                }}
                alt="career-naksha"
              />
            </div>
          </div>
          <span>
            <Button
              size="small"
              onClick={() => {
                routeToHome();
              }}
              style={{
                color: '#42b7a7',
              }}>
              Posts
            </Button>
            <Button
              size="small"
              style={{
                color: '#42b7a7',
                borderRadius: '0px',
              }}
              onClick={() => {
                if (!user) {
                  dispatch(setAlert('Please Login', 'danger'));
                } else {
                  routeToCreate();
                }
              }}>
              Create
            </Button>
          </span>
        </div>
        {/* {console.log(post)} */}
        {post && (
          <Card>
            <CardHeader
              avatar={
                <img
                  style={{
                    objectFit: 'contain',
                    height: '60px',
                    width: '60px',
                    borderRadius: '50%',
                  }}
                  aria-label="recipe"
                  src={post.avatar}
                  alt={post.name}></img>
              }
              title={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: 'inline',
                    }}
                    component="span"
                    variant="h6"
                    color="text.primary">
                    {post.name}
                  </Typography>
                </React.Fragment>
              }
              subheader={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: 'inline',
                    }}
                    component="span"
                    variant="subtitle1"
                    color="text.primary">
                    <Moment filter={format} date={post.date} />
                  </Typography>
                </React.Fragment>
              }
            />
            <CardContent>
              <h3 style={{ marginLeft: '10px' }}>{post.title}</h3>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  fontSize: '20px',
                  marginLeft: '15px',
                  marginTop: '30px',
                }}>
                {HtmlParser(post.text)}
              </Typography>
            </CardContent>
            <TextField
              label="Enter Comment"
              value={text}
              name="text"
              onChange={(e) => setText(e.target.value)}
              style={{
                marginLeft: '28px',
                width: '80%',
                paddingBottom: '30px',
                marginTop: '20px',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <i
                        className="bx bx-send"
                        onClick={() => {
                          onCommentSubmit();
                        }}></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {post && post.comments.length > 0 && (
                <div>
                  {post.comments.map((p) => (
                    <ListItem
                      key={p}
                      disablePadding
                      style={{
                        marginLeft: '26px',
                        marginBottom: '20px',
                        paddingRight: '10%',
                      }}>
                      <ListItemAvatar>
                        <img
                          alt="Remy Sharp"
                          src={p.avatar}
                          style={{
                            objectFit: 'contain',
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{
                                display: 'inline',
                              }}
                              component="span"
                              variant="subtitle1"
                              color="text.primary">
                              {p.name}
                            </Typography>
                          </React.Fragment>
                        }
                        style={{
                          marginLeft: '10px',
                        }}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{
                                display: 'inline',
                              }}
                              component="span"
                              variant="subtitle2"
                              color="text.primary">
                              {p.text}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      {p.user == localStorage.getItem('userId') ? (
                        <i
                          className="fa-solid fa-trash-can"
                          onClick={() =>
                            dispatch(deleteComment(post._id, p._id))
                          }></i>
                      ) : (
                        ''
                      )}
                    </ListItem>
                  ))}
                </div>
              )}
            </List>
          </Card>
        )}
      </div>
    </>
  );
}

DetailPost.propTypes = {
  getPost: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPost, addComment })(DetailPost);
