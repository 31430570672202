import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { backendurl } from '../../environment';

const TestRequest = ({ auth, history }) => {
  const [pack, setPack] = useState('E');
  const [value, setvalue] = useState(599);
  const [email, setemail] = useState('');

  const fillEmail = () => {
    if (auth.user?.email) {
      setemail(auth.user.email);
    }
  };

  const checkVerified = () => {
    const token = localStorage.getItem('token');
    if (auth.user?.isVerified === false || token == null) {
      console.log(auth.user);
      history.push('/request/verify');
    }
  };

  useEffect(() => {
    fillEmail();
    checkVerified();
  }, [auth]);

  function loadRazorpay() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';

    script.onerror = () => {
      alert('Razorpay SDK Failed To Load Are You Online ?');
    };

    script.onload = async () => {
      try {
        const result = await axios.post(
          'https://api.careernaksha.com/api/communityPsychometrics/create-order',
          {
            amount: value + '00',
          }
        );

        const { amount, id: order_id, currency } = result.data;
        let uuid;
        let d = new Date();
        uuid = d.valueOf();
        const options = {
          key: 'rzp_live_LaM5pgz5HtEDdv',
          amount: amount.toString(),
          currency: currency,
          name: 'Psychometric Test',
          description: 'Psychometric Test Pack: ' + pack,
          order_id: order_id,
          handler: async (response) => {
            const result = await axios.post(
              `${backendurl}/api/communityPsychometrics/pay-order`,
              {
                amount: amount,
                razorpayPaymentId: response.razorpay_payment_id || '',
                razorpayOrderId: response.razorpay_order_id || '',
                razorpaySignature: response.razorpay_signature || '',
                uuid: uuid,
                primary: pack,
                email: email,
                counsellorEmail: auth.user?.email,
              }
            );

            alert(result.data.msg);
            history.push('/mycodes');
          },
          prefill: {
            name: auth.user?.name,
            email: auth.user?.email,
            contact: auth.user?.phone,
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    document.body.appendChild(script);
  }

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>
        Get Psychometric Test
      </h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '40px',
        }}>
        <TextField
          InputLabelProps={{
            style: { color: '#000' },
          }}
          style={{ width: '60%', background: '#fff' }}
          id="outlined-basic"
          label="Email Id"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}>
        <select
          value={pack}
          onChange={(e) => {
            console.log(e.target.value);
            setPack(e.target.value);
            if (
              e.target.value === 'D' ||
              e.target.value === 'E' ||
              e.target.value === 'M' ||
              e.target.value === 'G' ||
              e.target.value === 'I'
            ) {
              console.log('YES');
              setvalue(599);
            } else {
              console.log('NO');
              setvalue(299);
            }
          }}
          style={{ width: '60%', height: '50px', borderColor: '#c1c1c1' }}>
          <option value="E"> Working Professional</option>
          <option value="D">Graduates UG/PG</option>
          <option value="C">Class 11th and 12th</option>
          <option value="B">Class 9th and 10th</option>
          <option value="A">Class 8th and Below</option>
          <option value="M">Career Test for Special Needs</option>
          <option value="I">Learning Styles</option>
          <option value="G">Aptitude Test</option>
        </select>
      </div>

      <h5 style={{ textAlign: 'center', marginTop: '20px' }}>
        Amount: {value} ₹ ( Inclusive Of Taxes + Gateway + Branding )
      </h5>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="text"
          style={{
            marginTop: '20px',
            color: '#fff',
            backgroundColor: '#000',
            borderRadius: '5px',
            padding: '10px 20px',
          }}
          onClick={() => loadRazorpay()}>
          PAY NOW
        </Button>
      </div>
      <h6 style={{ textAlign: 'center', marginTop: '20px' }}>
        The Codes Will Be Visible Under My Codes Section Under Profile
      </h6>
      <h6 style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>
        Caution: It is advisable to use Google Chrome or Mozilla Firefox
        browsers only (preferrable on laptop)
      </h6>
    </div>
  );
};

TestRequest.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TestRequest);
