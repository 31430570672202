import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './admin.css';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import PropTypes from "prop-types";


const AdminLeadsManage = ({ history,auth }) => {
  const [user, userData] = useState(null);
  const token = localStorage.getItem('token');

  const checkVerified = () => {
    if(auth.user?.isAdmin === false || token == null){
      history.push("/");
    }
  }

  useEffect(() => {
    checkVerified();
    axios
      .get('https://api.careernaksha.com/api/community/users/getAllLeads')
      .then((response) => {
        userData(response.data);
      });
  }, []);

  if (!user) return null;

  return (
    <div className='users-manager' style={{ marginTop: 20 }}>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>No. Of Leads</th>
            <th>See Leads In Detail</th>
          </tr>
        </thead>
        <tbody>
          {user.map((u) => (
            <tr>
              <td>{u.name}</td>
              <td>{u.email}</td>
              <td>{u.phone}</td>
              <td>{u.leads.length}</td>
              <td>
                <Button
                  href='#text-buttons'
                  onClick={() => {
                    history.push(`/admin/l/${u.phone}`);
                  }}
                >
                  Check Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

AdminLeadsManage.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(AdminLeadsManage);
