import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './Members.css';
import ListItemButton from '@mui/material/ListItemButton';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function ImpLinks({ history }) {
  const [impPosts, setimpPosts] = useState([]);
  useEffect(() => {
    const fetchImpPosts = async () => {
      const links = await axios.get(
        'https://api.careernaksha.com/api/community/posts/impPosts'
      );
      setimpPosts(links.data);
    };
    fetchImpPosts();
  }, []);

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 310,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 300,
          marginTop: 4,
          marginLeft: '0.5em',
          '& ul': { padding: 0 },
        }}
        subheader={
          <h5
            style={{
              textAlign: 'center',
              color: '#42b7a7',
              backgroundColor: '#EAEAEA',
              padding: '0.6em',
            }}>
            Some Important Links
          </h5>
        }
        className="members-list">
        {impPosts.map((value, i) => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <Link
              key={i}
              style={{ color: '#42b7a7' }}
              to={`/post/${value.slug}`}>
              <ListItem>
                <ListItemButton>
                  <ListItemText id={labelId} primary={value.title} />
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </>
  );
}
