import axios from 'axios';
import { setAlert } from './alert';

import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
} from './types';
import { backendurl } from '../environment';

//LOAD USER

export const loaduser = () => async (dispatch) => {
  if (localStorage.getItem('token')) {
    let token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['x-auth-token'] = token;
    }
  }

  try {
    const res = await axios.get(
      'https://api.careernaksha.com/api/community/auth'
    );

    localStorage.setItem('userId', res.data._id);

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User

export const register =
  ({ name, email, password, phone }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      name,
      email: email?.toLowerCase(),
      password,
      phone,
    });

    try {
      const res = await axios.post(
        'https://api.careernaksha.com/api/community/users',
        body,
        config
      );

      console.log(res.data);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
    dispatch(loaduser());
  };

export const registerRefer =
  ({ name, email, password, phone }, origUser) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      name,
      email: email?.toLowerCase(),
      password,
      phone,
      origUser,
    });

    try {
      const res = await axios.post(
        'https://api.careernaksha.com/api/community/users/refer',
        body,
        config
      );

      console.log(res.data);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
    dispatch(loaduser());
  };
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email: email?.toLowerCase(), password });

  try {
    const res = await axios.post(
      `${backendurl}/api/community/auth`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
  dispatch(loaduser());
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userId');
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_PROFILE });
  dispatch(loaduser());
};
