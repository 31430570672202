import axios from 'axios';

// ! CONSTANTS

const USER_PRODUCT_REQUEST = 'USER_PRODUCT_REQUEST';
const USER_PRODUCT_SUCCESS = 'USER_PRODUCT_SUCCESS';
const USER_PRODUCT_FAIL = 'USER_PRODUCT_FAIL';

// ! Reducer

export default function (state = [], action) {
  switch (action.type) {
    case USER_PRODUCT_REQUEST:
      return { userRequest: [] };
    case USER_PRODUCT_SUCCESS:
      return { userRequest: action.payload };
    case USER_PRODUCT_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
}

export const userProductAction = () => async (dispatch) => {
  try {
    dispatch({ type: USER_PRODUCT_REQUEST });

    const headers = {
      'x-auth-token': localStorage.getItem('token'),
    };

    const { data } = await axios.get(
      'https://api.careernaksha.com/api/community/users/getMyRequests',
      { headers }
    );
    dispatch({ type: USER_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_PRODUCT_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
