import React, { useState } from 'react';
import './Pagination.css';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];
  const [arr, setarr] = useState(1);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2%',
        width: '50%',
      }}>
      <nav className="page">
        <ul className="pagination">
          {pageNumbers.length > 1 &&
            pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <span
                  onClick={() => {
                    setarr(number);
                    paginate(number);
                  }}
                  className={arr == number ? 'pageStyle bgc' : 'pageStyle'}>
                  {number}
                </span>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
