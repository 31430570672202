import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { setAlert } from '../../actions/alert';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const ResetPwd = ({ match }) => {
  const dispatch = useDispatch();

  const [token, setToken] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();

  useEffect(() => {
    setToken(match.params.token);
  }, [match]);

  const passwordReset = async (password, passwordConfirm, token) => {
    if (password && password == passwordConfirm) {
      const body = { password: password };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const { data } = await axios.put(
          `https://api.careernaksha.com/api/community/users/resetpwd/${token}`,
          JSON.stringify(body),
          config
        );
        if (data) {
          dispatch(
            setAlert(
              'Congrats password has been successfully updated',
              'success'
            )
          );
        }
      } catch (error) {
        dispatch(setAlert('Token has been utilize or expired', 'danger'));
      }
    } else {
      dispatch(setAlert("Password Doesn't Match", 'danger'));
    }
  };
  return (
    <div>
      <Card sx={{ minWidth: 250, marginTop: 5, maxWidth: '50%' }}>
        <CardContent>
          <p>Enter your new password below and password confirmation</p>

          <TextField
            id="outlined-basic"
            label="Enter Password"
            variant="outlined"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: 3, width: '100%' }}
          />
          <TextField
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            name="password"
            type="text"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            style={{ marginTop: 25, width: '100%' }}
          />
        </CardContent>
        <CardActions>
          <Button
            size="small"
            style={{ color: '#42b7a7' }}
            onClick={() => passwordReset(password, passwordConfirm, token)}>
            Reset Password
          </Button>

          <Button size="small" style={{ color: '#42b7a7' }}>
            Close
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default ResetPwd;
