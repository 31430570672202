import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Navbar from './components/layout/Navbar';

import { Provider } from 'react-redux';
import store from './store';
import Landing from './screens/landingScreen/Landing';
import Members from './components/layout/Members';
import ImpLinks from './components/layout/ImpLinks';
import ButtonComponent from './components/AuthComponents/ButtonComponent';

// !
import Profile from './screens/userProfile/Profile.js';
import AdminPanel from './screens/adminPanel/AdminPanel';
import ForgetPassword from './components/AuthComponents/ForgetPassword';
import ResetPwd from './components/AuthComponents/ResetPwd';
import MemberProfile from './screens/memberProfile/MemberProfile';

// !

import PrivateRoute from './components/routing/PrivateRoute';
import { useHistory } from 'react-router-dom';

import CreatePost from './screens/createPostScreen/createPost';
import DetailPost from './screens/detailedPostScreen/detailPost';

import Register from './components/AuthComponents/Register';
import { loaduser } from './actions/auth';
import Login from './components/AuthComponents/Login';
import Alert from './components/layout/Alert';
import Login1 from './screens/mobileScreen/MobLogin';
import Register1 from './screens/mobileScreen/MobRegister';
import PrivatePosts from './components/layout/PrivatePosts';
import AdminRequestsManage from './screens/adminPanel/AdminRequestsManage';
import Users from './screens/memberProfile/Users';
import AdminLeadsManage from './screens/adminPanel/AdminLeadsManage';
import DetailedLeads from './screens/adminPanel/DetailedLeads';
import TestRequest from './screens/psychometricRequest/TestRequest';
import AdminVerifyAccount from './screens/adminPanel/AdminVerifyAccount';
import TestCodes from './screens/myTestCodes/TestCodes';
import RequestVerification from './screens/requestVerification/RequestVerification';
import AdminListOrders from './screens/adminPanel/AdminListOrders';

import Refer from './screens/refer/Refer';
import tools from './screens/tools/tools';
import AdminVerifiedUSers from './screens/adminPanel/AdminVerifiedUSers';
import EditProfile from './screens/EditProfileScreen/EditProfile';
import RegisterNewRefer from './screens/referRegisterScreen/RegisterNewRefer';
import AdminPointsCredit from './screens/adminPanel/AdminPointsCredit';
import AdminCertRequests from './screens/adminPanel/AdminCertRequests';
import AssignTestCode from './screens/AssignTest/AssignTestCode';

function App() {
  const history = useHistory();
  useEffect(() => {
    store.dispatch(loaduser());
  }, []);
  const [showLogin, setshowLogin] = useState(true);
  const [showLoginCard, setShowCard] = useState(false);
  const [showRegisterCard, setshowRegisterCard] = useState(false);

  const onLoginClick = () => {
    console.log('Called');
    setshowLogin(false);
    setShowCard(true);
    setshowRegisterCard(false);
  };

  const onCloseClick = () => {
    setshowLogin(true);
    setShowCard(false);
    setshowRegisterCard(false);
  };

  const onRegisterClick = () => {
    setShowCard(false);
    setshowRegisterCard(true);
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="row">
          <div className="col-lg-9 col-xl-9 col-sm-12">
            <Alert className="mt-5" />
            <Navbar />

            <Route exact path="/refer" component={Refer} />

            {/*  */}
            <Route path="/admin/" component={AdminPanel} exact />
            <Route path="/admin/r/" component={AdminRequestsManage} exact />
            <Route path="/admin/l/" component={AdminLeadsManage} exact />
            <Route path="/admin/points/" component={AdminPointsCredit} exact />
            <Route path="/admin/l/:id" component={DetailedLeads} exact />
            <Route path="/admin/verify" component={AdminVerifyAccount} exact />
            <Route path="/admin/orders" component={AdminListOrders} exact />
            <Route
              path="/admin/verifiedUsers"
              component={AdminVerifiedUSers}
              exact
            />
            <Route
              path="/admin/certRequests"
              component={AdminCertRequests}
              exact
            />
            <Route
              path="/request/verify"
              component={RequestVerification}
              exact
            />
            <Route path="/profile/:user" component={Profile} />
            <Route path="/editProfile" component={EditProfile} />
            <Route path="/users" component={Users}></Route>
            <Route path="/counsellor/:slug" component={MemberProfile} />
            {/* <Route path='/allUsers' component={AllUsers} /> */}
            <PrivateRoute path="/private-posts/" component={PrivatePosts} />

            {/*  */}
            <Fragment>
              <Route exact path="/" component={Landing} />

              <Route exact path="/tools" component={tools} />
              <Route exact path="/test" component={TestRequest} />
              <Route exact path="/mycodes" component={TestCodes} />
              <Route exact path="/register" component={Register1}></Route>
              <Route exact path="/login" component={Login1}></Route>
              <Route exact path="/assigntest" component={AssignTestCode} />

              <Route
                exact
                path="/forget-password/"
                onCloseClick={onCloseClick}
                component={ForgetPassword}></Route>
              <Route
                exact
                path="/resetpwd/:token"
                onCloseClick={onCloseClick}
                component={ResetPwd}></Route>
              <Switch>
                <PrivateRoute
                  exact
                  path="/create-post"
                  component={CreatePost}
                />
                <Route exact path="/post/:id" component={DetailPost} />
                <Route exact path="/refer/:id" component={RegisterNewRefer} />
              </Switch>
            </Fragment>
          </div>
          <div className="col-lg-3 col-xl-3 col-sm-0">
            {showLogin && <ButtonComponent onLoginClick={onLoginClick} />}
            {showLoginCard && (
              <Login
                onCloseClick={onCloseClick}
                onRegisterClick={onRegisterClick}
              />
            )}
            {showRegisterCard && <Register onCloseClick={onCloseClick} />}

            <Members history={history} />
            <ImpLinks history={history} />
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
