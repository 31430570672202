import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../actions/auth';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Refer from './refer.png';
import './Navbar.css';

function Navbar({ auth: { isAuthenticated, loading, user } }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState('header_toggle');
  const [menu, setmenu] = useState('bx bx-menu');
  const [nav, setNav] = useState('');
  const [headerpd, setHeaderpd] = useState('');

  const bodyel = document.getElementById('body-pd');
  const toggling = () => {
    if (menu === 'bx bx-x') {
      setToggle('header_toggle');
      setNav('');
      setHeaderpd('');
      bodyel.classList.toggle('body-pd');
      setmenu('bx bx-menu');
    } else {
      setToggle('show');
      setNav('show');
      setHeaderpd('body-pd');
      bodyel.classList.toggle('body-pd');
      setmenu('bx bx-x');
    }
  };

  return (
    <>
      <header className={`header ${headerpd} `} id="header">
        <div className={toggle} onClick={toggling}>
          <i className={menu} id="header-toggle" style={{ color: '#000' }}></i>
        </div>

        {!(
          isAuthenticated &&
          !loading &&
          user &&
          localStorage.getItem('token')
        ) ? (
          <div className="header_login">
            <Link
              to="/login"
              style={{
                textDecoration: 'none',
                color: '#fff',
                backgroundColor: '#42b7a7',
              }}>
              Join
            </Link>
          </div>
        ) : (
          <div className="user-profile">
            <Link to={`/profile/${user.slug}`}>
              <button className="btn btn-block user-details-navbar">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <ListItemButton className="listItem">
                    <ListItemAvatar className="listAvatar-navbar">
                      <Avatar
                        className="avatar"
                        height="45"
                        width="45"
                        alt={`Avatar`}
                        src={user.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText className="listText" primary={user.name} />
                  </ListItemButton>
                  <i
                    className="fa fa-sign-out nav_icon"
                    aria-hidden="true"
                    style={{
                      color: '#42b7a7',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(logout());
                      history.push('/');
                      window.location.reload();
                    }}></i>
                </div>
              </button>
            </Link>
          </div>
        )}
      </header>
      <div className={`l-navbar ${nav}`} id="nav-bar">
        <nav className="nav">
          <div>
            <Link to="/">
              <span href="#" className="nav_logo">
                <i className="bx bx-star nav_logo-icon"></i>
                <span className="nav_logo-name">Community</span>
              </span>
            </Link>
            <div className="nav_list">
              <Link to="/">
                <span className="nav_link active">
                  <i className="bx bx-home nav_icon"></i>
                  <span className="nav_name">Home</span>
                </span>
              </Link>
              {user && (
                <>
                  <Link to="/profile/test">
                    <span className="nav_link ">
                      <AccountCircleIcon fontSize="small" />
                      <span className="nav_name">Profile</span>
                    </span>
                  </Link>
                  <Link to="/tools">
                    <span className="nav_link ">
                      <SettingsIcon fontSize="small" />
                      <span className="nav_name">Tools</span>
                    </span>
                  </Link>
                </>
              )}
              {user && (
                <>
                  <Link to="/refer">
                    <span className="nav_link ">
                      <img
                        src={Refer}
                        alt="refer"
                        style={{ marginLeft: '0.2em' }}></img>
                      <span
                        className="nav_name"
                        style={{ marginLeft: '0.1em' }}>
                        Refer
                      </span>
                    </span>
                  </Link>
                </>
              )}
              {user && user.isAdmin && (
                <Link to="/admin/">
                  <span className="nav_link ">
                    <i className="bx bx-user nav_icon"></i>
                    <span className="nav_name">Admin</span>
                  </span>
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
