import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deletePost } from '../actions/post';

const PostCard = ({ post, page }) => {
  const dispatch = useDispatch();

  return (
    <div className="post-card-container">
      <Link to={`/post/${post.slug}`}>{post.title}</Link>
      {page == 'Profile' ? (
        <i
          className="fa-solid fa-trash"
          onClick={() => {
            dispatch(deletePost(post._id));
            // dispatch(userPostAction());
          }}></i>
      ) : (
        ''
      )}
    </div>
  );
};

export default PostCard;
