import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import slugify from 'react-slugify';
import draftToHtml from 'draftjs-to-html';
import Grid from '@mui/material/Grid';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { addPost } from '../../actions/post';
import { connect } from 'react-redux';
import './createPost.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { borderRadius } from '@mui/system';

function CreatePost({ history, addPost, auth: { user, loading } }) {
  const [title, setTitle] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState('');
  const [message, setMessage] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const wrapperStyle = {
    border: '1px solid #c1c1c1',
    borderRadius: '4px',
  };

  const editorStyle = {
    paddingTop: '0',
    paddingLeft: '1rem',
    height: '10rem',
    fontSize: '18px',
    width: '100%',
  };

  const routeToHome = () => {
    history.push('/');
  };

  const onSubmitPost = () => {
    setTitle('');
    setText('');
    setMessage(text);
    setIsPrivate(false);
    setEditorState(EditorState.createEmpty());

    if (title === '') {
      alert('Please Enter Title');
    }

    if (text === '') {
      alert('Please Enter Content');
    }

    if (title !== '' && text !== '') {
      let slug = slugify(title);
      addPost({ title, text, slug, isPrivate });
    }
    routeToHome();
  };

  return (
    <>
      <div className='card mb-3 custom-card'>
        <div className='row no-gutters'>
          <div className='col-md-8'>
            <div className='card-body'>
              <h5 className='card-title'>
                Welcome to Counsellors Community by CCI & CareerNaksha
              </h5>
              <p
                className='card-text'
                style={{ fontSize: '18px', paddingTop: '30px' }}
              >
                This community is for you to practice counselling better.
                Communicate with other fellow counsellors. Ask your questions,
                provide answers and share your thoughts with the community. We
                reward the top counsellors for their unbiased knowledge & ideas.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <img
              src='https://ik.imagekit.io/careerNaksha/Frame_1__2__27UoD81De.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649230937446'
              className='card-img custom-img'
              style={{
                paddingTop: '40px',
                imageRendering: '-webkit-optimize-contrast',
              }}
              alt='career-naksha'
            />
          </div>
        </div>
        <span>
          <Button
            size='small'
            onClick={() => {
              routeToHome();
            }}
            style={{
              color: '#42b7a7',
            }}
          >
            Posts
          </Button>
          <Button
            size='small'
            style={{
              color: '#42b7a7',
              borderRadius: '0px',
              borderBottom: '2px solid #42b7a7',
            }}
          >
            Create
          </Button>
          <Button
            size='small'
            style={{
              color: '#42b7a7',
              borderRadius: '0px',
            }}
            onClick={() => history.push('/private-posts/')}
          >
            Private Posts
          </Button>
        </span>
      </div>
      <div className='post-section' style={{ background: '#fff' }}>
        {!loading && user && (
          <Card
            style={{
              height: 'auto',
            }}
          >
            <div className='create-post-form'>
              <CardHeader
                avatar={
                  <img aria-label='counsellor' src={user.avatar}  style={{objectFit: 'contain',height:'60px',width:'60px'}}></img>
                }
                title={user.name}
              />
              <CardActions className='card-action'>
                <Button
                  className='post-button'
                  size='small'
                  style={{ color: '#fff', margin: 'auto' }}
                  onClick={() => onSubmitPost()}
                >
                  post
                </Button>
              </CardActions>
            </div>

            <CardContent>
              <TextField
                className='custom-textfield'
                fullWidth
                label='Title Of Post'
                id='fullWidth'
                style={{ fontSize: '18px' }}
                value={title}
                name='title'
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className='editor'>
                <Editor
                  placeholder='Content of Post'
                  initialEditorState={editorState}
                  wrapperStyle={wrapperStyle}
                  editorStyle={editorStyle}
                  editorClassName='demo-editor'
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'embedded',
                      'emoji',
                      'image',
                      'remove',
                      'history',
                    ],
                    link: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      dropdownClassName: undefined,
                      showOpenOptionOnHover: false,
                      defaultTargetOption: '_self',
                      options: ['link', 'unlink'],
                      linkCallback: undefined,
                    },
                    embedded: {
                      defaultSize: {
                        width: '100%',
                        height: '300px',
                      },
                    },
                    image: {
                      defaultSize: {
                        width: '100%',
                        height: '100%',
                      },
                    },
                    inline: {
                      options: ['italic', 'bold'],
                      bold: { className: 'demo-option-custom' },
                      italic: { className: 'demo-option-custom' },
                      underline: {
                        className: 'demo-option-custom',
                      },
                      strikethrough: {
                        className: 'demo-option-custom',
                      },
                      monospace: {
                        className: 'demo-option-custom',
                      },
                      superscript: {
                        className: 'demo-option-custom',
                      },
                      subscript: {
                        className: 'demo-option-custom',
                      },
                    },
                    blockType: {
                      className: 'demo-option-custom-wide',
                      dropdownClassName: 'demo-dropdown-custom',
                    },
                    fontSize: {
                      className: 'demo-option-custom-medium',
                    },
                  }}
                />
              </div>
              <input
                type='checkbox'
                name='isPrivate'
                id='isPrivate'
                value={true}
                onChange={(e) => {
                  setIsPrivate(e.target.value);
                }}
              />
              <label
                style={{ padding: '1rem 1rem 0', cursor: 'pointer' }}
                htmlFor='isPrivate'
              >
                Mark as Private
              </label>
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
}

CreatePost.propTypes = {
  addPost: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addPost })(CreatePost);
