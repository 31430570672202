import axios from 'axios';
import { userPostAction } from '../redux-action-reducer/user-post';
import { setAlert } from './alert';
import {
  ADD_POST,
  GET_POST,
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  ADD_COMMENT,
  REMOVE_COMMENT,
  POST_STATUS_UPDATE_REQUEST,
  POST_STATUS_UPDATE_SUCCESS,
  POST_STATUS_UPDATE_FAIL,
} from './types';

//GET POSTS

export const getPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(
      'https://api.careernaksha.com/api/community/posts'
    );
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const addLike = (postId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `https://api.careernaksha.com/api/community/posts/like/${postId}`
    );
    dispatch({
      type: UPDATE_LIKES,
      payload: { postId, likes: res.data },
    });
  } catch (error) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deletePost = (postId) => async (dispatch) => {
  try {
    dispatch({ type: POST_STATUS_UPDATE_REQUEST });
    const headers = {
      'x-auth-token': localStorage.getItem('token'),
    };

    await axios.delete(
      `https://api.careernaksha.com/api/community/posts/${postId}`,
      { headers }
    );

    dispatch(setAlert('Post Remove', 'success'));
    dispatch(userPostAction());
  } catch (error) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const addPost =
  ({ title, text, slug, isPrivate }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
    };
    const body = {
      title: title,
      text: text,
      slug: slug,
      isPrivate: isPrivate == 'true' ? true : false,
    };
    console.log(body);

    try {
      const res = await axios.post(
        `https://api.careernaksha.com/api/community/posts`,
        body,
        config
      );
      dispatch({
        type: ADD_POST,
        payload: res.data,
      });

      dispatch(setAlert('Post Created', 'success'));
    } catch (error) {
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
      window.scroll(0, 0);
      dispatch(setAlert('Post With Same Title Exist', 'danger'));
    }
  };

export const getPost = (id) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      let token = localStorage.getItem('token');
      axios.defaults.headers.common['x-auth-token'] = token;
    }
    const res = await axios.get(
      `https://api.careernaksha.com/api/community/posts/${id}`
    );

    dispatch({
      type: GET_POST,
      payload: res.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: 'POST ALREADY EXIST',
        status: error.response.status,
      },
    });
  }
};

export const addComment = (postId, formData) => async (dispatch) => {
  if (localStorage.getItem('token')) {
    let token = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = token;
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `https://api.careernaksha.com/api/community/posts/comment/${postId}`,
      formData,
      config
    );
    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });

    dispatch(setAlert('Comment Added', 'success'));
  } catch (error) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const deleteComment = (postId, commentId) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      let token = localStorage.getItem('token');
      axios.defaults.headers.common['x-auth-token'] = token;
    }
    await axios.delete(
      `https://api.careernaksha.com/api/community/posts/comment/${postId}/${commentId}`
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId,
    });

    dispatch(setAlert('Comment Removed', 'success'));
  } catch (error) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// ? action to made changes from admin pannel
export const adminPannelAction = (postId, actionType) => async (dispatch) => {
  try {
    if (actionType == 'UPDATE') {
      dispatch({ type: POST_STATUS_UPDATE_REQUEST });
      await axios.put(
        `https://api.careernaksha.com/api/community/posts/imp/${postId}`
      );

      dispatch(setAlert('Post Status Change to Important', 'success'));
    }
    if (localStorage.getItem('token') && actionType == 'DELETE') {
      dispatch({ type: POST_STATUS_UPDATE_REQUEST });
      await axios.delete(
        `https://api.careernaksha.com/api/community/posts/admin/${postId}`
      );

      dispatch(setAlert('Post Remove', 'success'));
    }

    const { data } = await axios.get(
      'https://api.careernaksha.com/api/community/posts'
    );
    dispatch({ type: POST_STATUS_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_STATUS_UPDATE_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
